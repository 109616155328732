import { useRuntimeConfig } from "#app/nuxt";
import { ApiClient } from "@t16t/sdk"
import { useFetch } from "@vueuse/core"

export function useApiClient() {
    const config = useRuntimeConfig();

    async function setupApiClient(token: string) {
        const { response, aborted } = await useFetch(`${config.public.sdkBaseUrl}/api/v1/logto-auth`, {
            beforeFetch({ options, cancel }) {
                if(!token) {
                    cancel();
                }
                options.headers = {
                    ...options.headers,
                    Authorization: `Bearer ${token}`,
                    SAAS_TENANT_ENTITY_ID: config.public.sdkTenantId,
                }
                return { options }
            }
        })
        .get()
        .json();

        if(aborted.value) {
            throw new Error('request aborted no token from idp');
        }

        ApiClient.newInstance({
            baseUrl: config.public.sdkBaseUrl,
            authorization: response.value?.headers.get('Authorization') || '',
            saasTenantEntityId: config.public.sdkTenantId,
          })
      }

      return {
        setupApiClient
      }
}